<template>
    <div>
        <v-app-bar
        app
        >
        <v-btn icon v-if="$route.meta.showBack" exact :to="{ name: $route.meta.backRoute, params: $route.meta.backParams, query: $route.meta.backQuery }" aria-label="back">
            <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <v-toolbar-title>
            {{ title }}
        </v-toolbar-title>
        </v-app-bar>
        <v-main>
            <slot name="content"></slot>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "Content",
    props: {
        title: String
    }
}
</script>
