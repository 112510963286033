const StepsStore = {
    namespaced: true,
    state: {
        groupedSteps: [
            {
                title: "Complete Load Component Testing",
                teaser: "[Client v21.12.21] [Server v4.0.1221]",
                steps: [
                    { id: "21.12.21.1221.a", title: "", teaser: "", coverImage: "" },
                    { id: "21.12.21.1221.b", title: "", teaser: "", coverImage: "" },
                ]
            },
            {
                title: "CSV Comma Handling",
                teaser: "[Client v21.10.20] [Server v4.0.1122]",
                steps: [
                    { id: "21.10.20.1122.a", title: "", teaser: "", coverImage: "" }
                ]
            },
            {
                title: "Load Commands & Errors",
                teaser: "[Client v21.10.20] [Server v4.0.1020]",
                steps: [
                    { id: "21.10.20.1020.a", title: "", teaser: "", coverImage: "" },
                    { id: "21.10.20.1020.b", title: "", teaser: "", coverImage: "" },
                    { id: "21.10.20.1020.c", title: "", teaser: "", coverImage: "" },
                ]
            },
            {
                title: "Basic Load Processing",
                teaser: "[Client v21.09.07] [Server v4.0.923]",
                steps: [
                    { id: "21.09.07.923.b", title: "", teaser: "", coverImgage: "" },
                ]
            },
            {
                title: "Report Fixes",
                teaser: "[Client v21.09.07] [Server v4.0.923]",
                steps: [
                    { id: "21.09.07.923.a", title: "", teaser: "", coverImgage: "" },
                ]
            },{
                title: "Zero Dollar Site Records",
                teaser: "[Client v21.09.07] [Server v4.0.914]",
                steps: [
                    { id: "21.09.07.914", title: "", teaser: "", coverImgage: "" },
                ]
            },{
                title: "Time Zones",
                teaser: "[Client v21.09.07] [Server v4.0.907]",
                steps: [
                    { id: "21.09.07.907", title: "", teaser: "", coverImage: "" },
                ]
            },{
                title: "Print Enabled",
                teaser: "[Client v21.08.10] [Server v4.0.824]",
                steps: [
                    { id: "21.08.10.824", title: "", teaser: "", coverImage: "" },
                ]
            },{
                title: "File Timestamps",
                teaser: "[Client v21.08.10] [Server v4.0.722]",
                steps: [
                    { id: "21.08.10.1", title: "", teaser: "", coverImage: "" },
                ]
            },{
                title: "Gen Commands",
                teaser: "[Client v21.06.24] [Server v4.0.0]",
                steps: [
                    { id: "21.06.24.1", title: "", teaser: "", coverImage: "" },
                    { id: "21.06.24.2", title: "", teaser: "", coverImage: "" }
                ]
            },{
                title: "Bid Files and Amendments",
                teaser: "[Client v21.06.11] [Server v4.0.0]",
                steps: [
                    { id: "21.06.11.1", title: "", teaser: "", coverImage: "" }
                ]
            },{
                title: "Installation",
                teaser: "[Client v21.06.04] [Server v4.0.0]",
                steps: [
                    { id: "21.06.04.1", title: "", teaser: "", coverImage: "" },
                    { id: "21.06.04.2", title: "", teaser: "", coverImage: "" }
                ]
            }
        ],
        steps: {
            "21.12.21.1221.a": {title: "", content: [{text: 'Test the Load component in your agency\'s typical bidding workflow<ul><li>Complete a bid opening on <a href="https://demo.bidx.com">demo.bidx.com</a></li><li>If applicable: Manual bid file processing</li></ul>'}]},
            "21.12.21.1221.b": {title: "", content: [{text: "Test Load component options<ul><li>Multi file processing<ul><li>Example: <code>Load.exe *</code></li><li>This will process all .ebsx files in the current directory.</li></ul></li><li>Drive, call order, and /date<ul><li>Example: <code>Load.exe . 001 002 /date=*</code></li><li>This will process any .ebsx file with a call order of 001 or 002 within the current directory, specified by the period, regardless of letting date.</li></ul></li><li>/Info<ul><li>Example: <code>Load.exe 1234.ebsx /info</code></li><li>Export format Column: This will result in only an output of the load report and bidbonds.csv</li><li>Export format Json: This will result in only an output of the load report</li></ul></li><li>/Out<ul><li>Example: <code>Load.exe 1234.ebsx /out=customload.xps</code></li><li>This will result in the normal execution and output of the Load component, but the load report will be named customload.xps instead of what is specified in the load.cfg file. This name should have an extension of .xps or .pdf based on your expected load report format.</li></ul></li><li>/appendlog<ul><li>Example: <code>Load.exe 1234.ebsx</code></li><li>Example: <code>Load.exe 5678.ebsx /appendlog</code></li><li>In the following two examples the bid files 1234.ebsx and 5678.ebsx are processed at different times by the Load component. However, the resulting load report should include information for both bids.</li></ul></li><li>/printebsx<ul><li>Example: <code>Load.exe 1234.ebsx /printebsx</code></li><li>This will result in the bid file, 1234.ebsx, being printed to the standard load report file name. No other Load component outputs will be generated.</li></ul></li></ul></li></ul>"}]},
            "21.10.20.1122.a": {title: "", content: [{text: "Test comma handling in the proposal description<ul><li>Steps:<ul><li>Set a proposal description that includes a comma (Note: make sure the description is wrapped in quotation marks).</li><li>Generate the .ebsx file and check that the whole description is displayed after opening.</li><li>Make a change to the description after the comma.</li><li>Generate an amendment file and ensure the change displays after opening the .ebsx file and applying the amendment.</li></ul></li></ul>"}]},
            "21.10.20.1020.a": {title: "", content: [{text: "Out of date client error message<ul><li>Test case:<ul><li>With a client version prior to the 21.10.20 version process a single .ebsx file, command below, and confirm the message indicating the client is out of date includes both the currently installed version and current version.</li><li>Command:<code>load /cfg=load.cfg 1234.ebsx</code></li></ul></li></ul>"}]},
            "21.10.20.1020.b": {title: "", content: [{text: "Test that the Load component processes a single bid file without specifying the load.cfg file<ul><li>Command: <code>load 1234.ebsx</code></li><li>Test cases:<ul><li>If a load.cfg file, with the same name, is present in the current directory the .ebsx file will be processed</li><li>If a load.cfg file, with the same name, is NOT present in the current directory an error message displays stating the configuration file could not be found with instructions of how to correct the error.</li></ul></li></ul>"}]},
            "21.10.20.1020.c": {title: "", content: [{text: "Test the <code>/version</code> command for the Load component<ul><li>Command: <code>load /version</code></li><li>Test case:<ul><li>The console should return the Load version (the server version) and the Load Client version (the currently installed version).</li></ul></li></ul>"}]},
            "21.09.07.923.b": {title: "", content: [{text: "Test that bid files are processed and that all expected reports and interface files are produced when explicitly naming the load configuration file<ul><li>Command: <code>load /cfg=load.cfg 1234.ebsx</code><ul><li>Currently Load must be run with the cfg file specified, so make sure you are using the command format above to test.</li><li>A wild card, <code>*</code>, may be used in place of a names bid file.</li></ul></li></ul>"}]},
            "21.09.07.923.a": {title: "", content: [{text: "The issue with reporting has been resolved<ul><li>Check that <code>gen /p</code> and  <code>gen /print</code> are working again</li></ul>"}]},
            "21.09.07.914": {title: "", content: [{text: "Test that proposal site records with an assigned cost per day of $0 require bidders to enter a bid of days.<ul><li>Site records with a blank cost per day should not require an entry of days.</li><li>All other site records should remain unchanged.</li></ul>"}]},
            "21.09.07.907": {title: "", content: [{text: "Test that files created using the Gen component are properly time stamped with the user's timezone and are not always in Eastern time."}] },
            "21.08.10.824": {title: "", content: [{text: "Test the <code>/print</code> command for the Gen component<ul><li>Command: <code>>gen /print</code></li><li>This command should generate a bid or amendment file and also create a printed file, in XPS or PDF format, in the same folder as the bid file.</li><li>If the local bid file is current (exists and does not require an amendment) the printed file should still be generated.</li></ul>"}]},
            "21.08.10.1": { title: "", content: [{text: "Ensure that the files in the local folder maintain the correct date time stamp after executing a Gen command<ul><li>Example: The gen.cfg file timestamp should not update to the current date time after generating a bid file.</li></ul>"}] },
            "21.06.24.1": { title: "", content: [{text: "Test all gen commands except <code>/print</code><ul><li>Use command <code>>gen /?</code> for a full list</li></ul>"}] },
            "21.06.24.2": { title: "", content: [{text: "Test that bid and amendment file generation are now working."}] },
            "21.06.11.1": { title: "", content: [{text: "Test bid and amendment file generation.<ul><li>Files may be opened and used to check that everything generated correctly.</li></ul>"}] },
            "21.06.04.1": { title: "", content: [{text: "Test the now separate Entry and Gen and Load Installations"}] },
            "21.06.04.2": { title: "", content: [{text: "Execute the gen version command and ensure a response is received. <ul><li>Command: <code>>gen /version</code></li><li>Out of date: If the installation is out of date the Gen Client version will be marked with an asterisk followed by a message with the currently installed version.</li><li>Current version: If the installation is the latest then the Gen Client version will not be marked with an asterisk.</li></ul>" }] },
        }
    },
    getters: {
        getGroupedSteps: (state) => {
            return state.groupedSteps;
        },
        getSteps: (state) => (id) => {
            return state.steps[id];
        }
    }
};

export default StepsStore;