const titlePrefix = 'FAQ';

export default [
    {
        path: '/faq',
        name: 'FAQ',
        meta: {
            title: `${titlePrefix}`
        },
        component: () => null,
        beforeEnter: (to) => {
            console.log(to);
            window.open('https://www.aashtowareproject.org/faqs-bids');
            return false;

        }
    }
]