//import store from '../store';
const titlePrefix = 'Settings';

export default [
    {
        path: '/status',
        name: 'Status',
        meta: {
            title: `${titlePrefix}`
        },
        component: () => import('../views/Status.vue'),
        beforeEnter: (to, from, next) => {
            //store.dispatch('about/refreshGroupedArticles');
            next();
        }
    }
]