import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import light from './themes/light';
//import dark from './themes/dark';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            settings: 'mdi mdi-cog',
            whatsNew: 'mdi mdi-alert-decagram'
        }
    },
    /*theme: {
        themes: { light, dark },
        options: {
            customProperties: true
        }
    }*/
});
