<template>
  <v-app>
    <v-navigation-drawer
      permanent
      app
    >
      <v-list-item>
        <v-list-item-avatar rounded="0" class="mr-4">
          <v-img src="favicon.ico" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            AASHTOWare
          </v-list-item-title>
          <v-list-item-subtitle class="grey--text text--darken-2 font-weight-bold">
            Project Bids™
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item v-for="item in items"
          :key="item.title"
          :to="item.route"
          link
        >
          <v-list-item-icon  >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <router-view :key="$route.fullPath" ref="main" />
    <v-footer padless fixed style="padding: 0 0 0 256px">
      <v-card>
        <v-card-text class="text-center">AASHTOWare Project Bids&trade; is a trademark and the AASHTOWare Project&trade; logo is a registered mark of the American Association of State Highway and Transportation Officials (AASHTO, Inc.) Copyright &copy; 2023. All rights reserved.</v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => {
    return {
      items: [
        { title: 'Home', route: '/', icon: 'mdi-home' },
        { title: 'About',  route: '/about', icon: 'mdi-information' },
        { title: 'Status', route: '/status', icon: 'mdi-signal' },
        { title: 'FAQ', route: '/faq', icon: 'mdi-forum' }
      ]
    }
  },
};
</script>
