import Dashboard from '../views/Dashboard.vue'
import AboutRoutes from './about-routes';
import StatusRoutes from './status-routes';
import FaqRoutes from './faq-routes';

export default [
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            title: 'Home'
        },
        component: Dashboard,
        /*beforeEnter: (to, from, next) => {

        }*/
    },
    ...AboutRoutes,
    ...StatusRoutes,
    ...FaqRoutes,
]