const LatestStore = {
    namespaced: true,
    state: {
        groupedLatest: [
            {
                title: "Client",
                latest: [
                    { id: "Download", title: "Download", teaser: "Get the latest client version", coverImage: "" },
                    { id: "Client-21-6-4", title: "Change Log 21.6.4", teaser: "See what has changed to the Client", coverImage: "" },
                ]
            },
            {
                title: "Cloud",
                latest: [
                    { id: "Cloud-4-0-0", title: "Change Log 4.0.0", teaser: "See what has change in the Cloud", coverImage: "" }
                ]
            }
        ],
        latest: {
            "Download": { title: "Download", content: [{text: "Contact your EUD and have them log in to <a href='https://aashtowareproject.net'>aashtowareproject.net</a> and download the latest version of the AASHTOWare Project Bids Gen and Load."}] },
            "Client-21-6-4": { title: "Change Log 21.6.4", content: [{text: "The first release of AASHTOWare Project Gen and Load client, 21.6.4. Did you know we adopted a new release version to reflect the YY-MM-DD of when we release the software?"}] },
            "Cloud-4-0-0": { title: "Change Log 4.0.0", content: [{text: "This is the first release of AASHTOWare Project Bids Gen and Load in the Cloud! We have all the existing logic from Gen and are working hard to include Load and all buch of new features. Did you know that the dloud versions of Gen and Load will always be at the latest version even if your clients falls behind? No more installs on minor version updates!"}] },
        }
    },
    getters: {
        getGroupedLatest: (state) => {
            return state.groupedLatest;
        },
        getLatest: (state) => (id) => {
            return state.latest[id];
        }
    }
};

export default LatestStore;