//import store from '../store';
const titlePrefix = 'About';

export default [
    {
        path: '/about',
        name: 'About',
        meta: {
            title: `${titlePrefix}`
        },
        component: () => import('../views/about/About.vue'),
        beforeEnter: (to, from, next) => {
            //store.dispatch('about/refreshGroupedArticles');
            next();
        }
    },
    {
        path: '/about/:id',
        name: 'AboutDetails',
        meta: {
            showBack: true,
            backRoute: 'About',
            title: `${titlePrefix} - Details`
        },
        props: true,
        component: () => import('../views/about/AboutDetails.vue'),
        beforeEnter: (to, from, next) => {
            next();
        }
    }
]