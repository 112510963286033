<template>
    <Content title="Dashboard">
        <template v-slot:content>
            <div class="container d-flex flex-column">
                <div class="d-flex justify-center">
                    <v-img src="shield.png" max-width="292px" max-height="162px" class="flex mt-2 md12 xs12"/>
                </div>
                <div class="mt-6 md10 xs12">
                    <v-card>
                        <v-card-text class="text--primary body-1">
                            <p>Welcome to the new version of the AASHTOWare Project Bids&trade; Gen and Load components. The service will continue to be accessed using your local Gen.exe and Load.exe files, but you won’t have to do anything else. Future updates will be uploaded directly to the service without the need for any additional installations.</p>
                            <p class="mb-0">This site is dedicated to resources and documentation for the new service. You can check the service status, review the architecture, and more. If you need help or have any questions, please call Infotech at <a href="tel:+13523814400">(352) 381-4400</a> and ask for AASHTOWare Project Bids Support.</p>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </template>
    </Content>
</template>
<script>
import Content from './Content.vue'

export default {
    name: 'Dashboard',
    components: {
        Content
    },
    data () {
        return { }
    },
    computed: { },
    methods: { },
}
</script>
