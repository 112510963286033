import Vue from 'vue'
import Vuex from 'vuex'

import AboutStore from './about-store'
import LatestStore from './latest-store'
import StepsStore from './steps-store'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    about: AboutStore,
    steps: StepsStore,
    latest: LatestStore
  }
})
